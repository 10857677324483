@import "../theme/mixins";

$brand: 'marseille';
$brandAssets: "/assets/#{$brand}";

@font-face {
  font-family: AppFont;
  src: url("../assets/fonts/AvenirNextCyr-Regular.ttf");
}
@font-face {
  font-family: AppFontLight;
  src: url("../assets/fonts/AvenirNextCyr-Light.ttf");
}
@font-face {
  font-family: AppFontBold;
  src: url("../assets/fonts/AvenirNextCyr-Bold.ttf");
}
@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-Regular.ttf");
}


:root {
  --brand: $brand;
	--ion-color-primary: #6D6EBF;;
	--ion-color-primary-rgb: 109,110,191;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #6061a8;
	--ion-color-primary-tint: #7c7dc5;


	--ion-color-secondary: #F2E196;
	--ion-color-secondary-rgb: 242,225,150;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #d5c684;
	--ion-color-secondary-tint: #f3e4a1;

	--ion-color-tertiary: #2A3183;
	--ion-color-tertiary-rgb: 42,49,131;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #252b73;
	--ion-color-tertiary-tint: #3f468f;
  
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #66605a;
  --ion-color-dark-rgb: 102,96,90;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #5a544f;
  --ion-color-dark-tint: #75706b;

	--ion-color-medium: #26278C;
	--ion-color-medium-rgb: 38,39,140;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #21227b;
	--ion-color-medium-tint: #3c3d98;

  --ion-color-light: #B0B0B0;
	--ion-color-light-rgb: 176,176,176;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #9b9b9b;
	--ion-color-light-tint: #b8b8b8;

  --ion-font-family: AppFont, sans-serif;
  --ion-margin: 12px;
  --ion-padding: 12px;
}

h1 {
  font-family: AppFontBold, Helvetica, sans-serif;
}
h1,h3,h3,h4,h5, p {
  color: var(--ion-color-medium);
  margin: 0px;
}
.ion-page,
ion-footer,
ion-content {
  background: white;
  --background: white;
}
app-menu-modal {
  background: var(--ion-color-primary)  no-repeat right bottom / 50% !important;

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-modal {
  background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;
  --background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-home {
  // background:  var(--ion-color-primary) url(#{$brandAssets}/image-home.png) no-repeat center top / 100% !important;
  // background-size: cover !important;

  // @include mobile {
  //   background: var(--ion-color-primary) url(#{$brandAssets}/home_page_mobile.png) no-repeat center top / 100% !important;
  // }

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}

